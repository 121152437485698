@layer mantine {.m_468e7eda {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

  .m_468e7eda::-webkit-calendar-picker-indicator {
    display: none;
  }

  .m_468e7eda::-webkit-clear-button {
    display: none;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field,
  .m_468e7eda::-webkit-datetime-edit-minute-field,
  .m_468e7eda::-webkit-datetime-edit-second-field,
  .m_468e7eda::-webkit-datetime-edit-ampm-field {
    padding-top: 0;
    max-height: calc(1.875rem * var(--mantine-scale));
    display: inline;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field:focus, .m_468e7eda::-webkit-datetime-edit-minute-field:focus, .m_468e7eda::-webkit-datetime-edit-second-field:focus, .m_468e7eda::-webkit-datetime-edit-ampm-field:focus {
      background-color: var(--mantine-primary-color-filled);
      color: var(--mantine-color-white);
    }

.m_396ce5cb {
  --day-size-xs: calc(1.875rem * var(--mantine-scale));
  --day-size-sm: calc(2.25rem * var(--mantine-scale));
  --day-size-md: calc(2.625rem * var(--mantine-scale));
  --day-size-lg: calc(3rem * var(--mantine-scale));
  --day-size-xl: calc(3.375rem * var(--mantine-scale));
  --day-size: var(--day-size-sm);

  width: var(--day-size, var(--day-size-sm));
  height: var(--day-size, var(--day-size-sm));
  font-size: calc(var(--day-size) / 2.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: var(--mantine-radius-default);
  color: var(--mantine-color-text);
  opacity: 1;
}

  @media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_396ce5cb:where([data-static]) {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto;
    cursor: default;
  }

  .m_396ce5cb:where([data-weekend]) {
    color: var(--mantine-color-red-6);
  }

  .m_396ce5cb:where([data-outside]) {
    color: var(--mantine-color-dimmed);
    opacity: 0.5;
  }

  .m_396ce5cb:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_396ce5cb:where([data-hidden]) {
    display: none;
  }

  :where([data-mantine-color-scheme='light']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-dark-4);
}

  .m_396ce5cb:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-in-range]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-in-range]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  .m_396ce5cb:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range][data-first-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast);
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-selected]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-selected]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

.m_18a3eca {
  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: var(--wr-fz, var(--mantine-font-size-sm));
  text-transform: capitalize;
  padding-bottom: calc(var(--wr-spacing, var(--mantine-spacing-sm)) / 2);
}

.m_cc9820d3 {
  border-collapse: collapse;
  table-layout: fixed;
}

.m_8f457cd5 {
  padding: 0;
}

.m_8f457cd5:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_6cff9dea {
  --wn-size-xs: calc(1.875rem * var(--mantine-scale));
  --wn-size-sm: calc(2.25rem * var(--mantine-scale));
  --wn-size-md: calc(2.625rem * var(--mantine-scale));
  --wn-size-lg: calc(3rem * var(--mantine-scale));
  --wn-size-xl: calc(3.375rem * var(--mantine-scale));

  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: calc(var(--wn-size, var(--wn-size-sm)) / 2.8);
  text-align: center;
  width: var(--wn-size, var(--wn-size-sm));
}

.m_dc6a3c71 {
  --dpc-size-xs: calc(1.875rem * var(--mantine-scale));
  --dpc-size-sm: calc(2.25rem * var(--mantine-scale));
  --dpc-size-md: calc(2.625rem * var(--mantine-scale));
  --dpc-size-lg: calc(3rem * var(--mantine-scale));
  --dpc-size-xl: calc(3.375rem * var(--mantine-scale));
  --dpc-size: var(--dpc-size-sm);

  font-size: var(--dpc-fz, var(--mantine-font-size-sm));
  height: var(--dpc-size);
  width: calc((var(--dpc-size) * 7) / 3 + calc(0.09375rem * var(--mantine-scale)));
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--mantine-color-text);
  opacity: 1;
  border-radius: var(--mantine-radius-default);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_dc6a3c71:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_dc6a3c71:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-selected]):hover {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-selected]):active {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  .m_dc6a3c71:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-in-range]):hover {
      background-color: var(--mantine-primary-color-light);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-in-range]):active {
      background-color: var(--mantine-primary-color-light);
  }
}

  .m_dc6a3c71:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-first-in-range][data-last-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

.m_9206547b {
  border-collapse: collapse;
  border-width: 0;
}

.m_c5a19c7d {
  padding: 0;
}

.m_c5a19c7d:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_2a6c32d {
  border-collapse: collapse;
  border-width: 0;
  cursor: pointer;
}

.m_fe27622f {
  padding: 0;
}

.m_fe27622f:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_730a79ed {
  --dch-control-size-xs: calc(1.875rem * var(--mantine-scale));
  --dch-control-size-sm: calc(2.25rem * var(--mantine-scale));
  --dch-control-size-md: calc(2.625rem * var(--mantine-scale));
  --dch-control-size-lg: calc(3rem * var(--mantine-scale));
  --dch-control-size-xl: calc(3.375rem * var(--mantine-scale));
  --dch-control-size: var(--dch-control-size-sm);

  display: flex;
  max-width: calc(var(--dch-control-size) * 8 + calc(0.4375rem * var(--mantine-scale)));
  margin-bottom: var(--mantine-spacing-xs);
}

.m_f6645d97,
.m_2351eeb0 {
  height: var(--dch-control-size);
  border-radius: var(--mantine-radius-default);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 1;
  cursor: pointer;
}

@media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
      [data-mantine-color-scheme='light'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

.m_f6645d97:where(:disabled, [data-disabled]), .m_2351eeb0:where(:disabled, [data-disabled]) {
    opacity: 0.2;
    cursor: not-allowed;
  }

.m_2351eeb0 {
  width: var(--dch-control-size);
}

.m_f6645d97 {
  flex: 1;
  font-size: var(--dch-fz, var(--mantine-font-size-sm));
  font-weight: 500;
  text-transform: capitalize;
}

.m_367dc749 {
  width: 60%;
  height: 60%;
}

.m_367dc749:where([data-direction='next']) {
    transform: rotate(270deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='next']) {
      transform: rotate(90deg);
}

.m_367dc749:where([data-direction='previous']) {
    transform: rotate(90deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='previous']) {
      transform: rotate(270deg);
}

.m_30b26e33 {
  display: flex;
  gap: var(--mantine-spacing-md);
}

.m_6fa5e2aa {
  cursor: pointer;
  line-height: unset;
}

  .m_6fa5e2aa:where([data-read-only]) {
    cursor: default;
  }

.m_208d2562 {
  display: flex;
  align-items: stretch;
  margin-top: var(--mantine-spacing-md);
}

.m_62ee059 {
  flex: 1;
  margin-inline-end: var(--mantine-spacing-md);
}
}
@layer mantine {.m_e4d36c9b {
  min-width: calc(12.5rem * var(--mantine-scale));
  background-color: var(--mantine-color-body);
  box-shadow: var(--mantine-shadow-md);
  border-radius: var(--mantine-radius-default);
}

  :where([data-mantine-color-scheme='light']) .m_e4d36c9b {
    border: 1px solid var(--mantine-color-gray-3);
}

  :where([data-mantine-color-scheme='dark']) .m_e4d36c9b {
    border: 1px solid var(--mantine-color-dark-4);
}

.m_7f4bcb19 {
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-md);
  font-weight: 500;
  color: var(--mantine-color-bright);
}

.m_3de554dd {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  padding-top: 0;
}

.m_e4d36c9b:where([data-type='radial']) :where(.m_3de554dd) {
    padding-top: var(--mantine-spacing-sm);
  }

.m_b30369b5 {
  width: calc(0.75rem * var(--mantine-scale));
  height: calc(0.75rem * var(--mantine-scale));
}

.m_3de8964e {
  font-size: var(--mantine-font-size-sm);
  display: flex;
  justify-content: space-between;
}

.m_3de8964e:where(.m_3de8964e + .m_3de8964e) {
    margin-top: calc(var(--mantine-spacing-sm) / 2);
  }

.m_3de8964e[data-type='scatter'] .m_b30369b5 {
    display: none;
  }

.m_50186d10 {
  display: flex;
  align-items: center;
  gap: var(--mantine-spacing-sm);
  margin-inline-end: var(--mantine-spacing-xl);
}

.m_501dadf9 {
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.m_50192318 {
  color: var(--mantine-color-bright);
}

.m_847eaf {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

  .m_847eaf:where([data-centered]) {
    justify-content: center;
  }

  .m_847eaf:where([data-position='top']) {
    padding-bottom: var(--mantine-spacing-md);
  }

  .m_847eaf:where([data-position='bottom']) {
    padding-top: var(--mantine-spacing-md);
  }

.m_17da7e62 {
  display: flex;
  align-items: center;
  gap: calc(0.4375rem * var(--mantine-scale));
  padding: 7px var(--mantine-spacing-xs);
  border-radius: var(--mantine-radius-default);
  line-height: 1;
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_17da7e62:where(:hover) {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_17da7e62:where(:hover) {
      background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_17da7e62:where(:active) {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_17da7e62:where(:active) {
      background-color: var(--mantine-color-dark-5);
  }
}

.m_17da7e62[data-without-color] .m_6e236e21 {
    display: none;
  }

.m_8ff56c0d {
  font-size: var(--mantine-font-size-sm);
  margin: 0;
  padding: 0;
}

.m_a50f3e58 {
  display: block;
  width: 100%;

  --chart-bar-label-color: var(--mantine-color-dimmed);
}

  .m_a50f3e58 svg {
    overflow: visible;
  }

  :where([data-mantine-color-scheme='light']) .m_a50f3e58 {
    --chart-grid-color: rgba(173, 181, 189, 0.6);
    --chart-cursor-fill: rgba(173, 181, 189, 0.15);
}

  :where([data-mantine-color-scheme='dark']) .m_a50f3e58 {
    --chart-cursor-fill: rgba(105, 105, 105, 0.15);
    --chart-grid-color: rgba(105, 105, 105, 0.6);
}

  .m_a50f3e58 :where(text) {
    font-family: var(--mantine-font-family);
    color: var(--chart-text-color, var(--mantine-color-dimmed));
  }

.m_af9188cb {
  height: 100%;
  width: 100%;
}

.m_a50a48bc {
  stroke: var(--chart-grid-color);
}

.m_a507a517 {
  color: var(--chart-grid-color);
}

.m_2293801d {
  color: var(--chart-text-color, var(--mantine-color-dimmed));
}

.m_92b296cd {
  min-width: calc(12.5rem * var(--mantine-scale));
  background-color: var(--mantine-color-body);
  box-shadow: var(--mantine-shadow-md);
  border-radius: var(--mantine-radius-default);
  padding: var(--mantine-spacing-sm);
}

:where([data-mantine-color-scheme='light']) .m_92b296cd {
    border: 1px solid var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_92b296cd {
    border: 1px solid var(--mantine-color-dark-4);
}

.m_a410e613 {
  min-height: var(--chart-size, auto);
  height: var(--chart-size, auto);
  width: var(--chart-size, auto);
  min-width: var(--chart-size, auto);
}

  .m_a410e613 :where(*) {
    outline: 0;
  }

.m_ddb0bfe3 {
  fill: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.m_cd8943fd {
  min-height: var(--chart-size, auto);
  height: var(--chart-size, auto);
  width: var(--chart-size, auto);
  min-width: var(--chart-size, auto);
}

  .m_cd8943fd :where(*) {
    outline: 0;
  }

:where([data-mantine-color-scheme='light']) .m_1f271cf7 {
    --chart-grid-color: rgba(173, 181, 189, 0.6);
    --chart-text-color: var(--mantine-color-gray-7);
}
  :where([data-mantine-color-scheme='dark']) .m_1f271cf7 {
    --chart-grid-color: rgba(105, 105, 105, 0.6);
    --chart-text-color: var(--mantine-color-dark-1);
}

  .m_1f271cf7 :where(text) {
    font-size: var(--mantine-font-size-xs);
    fill: var(--chart-text-color, var(--mantine-color-dimmed));
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

.m_cf06f58c {
  height: 100%;
  width: 100%;
}

:where([data-mantine-color-scheme='light']) .m_cd2bd9e5 {
    --chart-empty-background: var(--mantine-color-gray-1);
    --chart-cursor-color: var(--mantine-color-gray-4);
}
  :where([data-mantine-color-scheme='dark']) .m_cd2bd9e5 {
    --chart-empty-background: var(--mantine-color-dark-6);
    --chart-cursor-color: var(--mantine-color-dark-4);
}

.m_6bcc3420 {
  padding: var(--mantine-spacing-md);
  box-shadow: var(--mantine-shadow-md);
  min-width: calc(12.5rem * var(--mantine-scale));
  font-size: var(--mantine-font-size-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:where([data-mantine-color-scheme='light']) .m_6bcc3420 {
    border: 1px solid var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_6bcc3420 {
    border: 1px solid var(--mantine-color-dark-4);
}

.m_80d531e7 {
  min-height: var(--chart-size, auto);
  height: var(--chart-size, auto);
  width: var(--chart-size, auto);
  min-width: var(--chart-size, auto);
}

  .m_80d531e7 :where(*) {
    outline: 0;
  }
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5fb25f343c7550ca-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_2382d5';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7db6c35d839a711c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_2382d5';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_2382d5 {font-family: '__Poppins_2382d5', '__Poppins_Fallback_2382d5';font-style: normal
}

